// UserHomePage.js
import React, { useState, useEffect } from "react";

import { checkIfTokenStillValid } from "../../repositories/utils";
import { useUser } from "../../hooks";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";

import {
  PageContainer,
  Subcontainer, 
  InvoicesCard,
  InvoicesTitle,
  InvoiceCard,
  InvoiceTitle,
  DownloadButton,
  InvoiceAmount,
} from './Invoices.styled';

// The component itself
export const Invoices = ({ history }) => {
  const {
    getInvoices,
    getInvoice
  } = useUser();
  const [invoices, setInvoices] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {
    async function fetchData() {
      checkIfTokenStillValid(history);
      const invoices = await getInvoices();
      setInvoices(invoices);
    }
    fetchData();
  }, [history, getInvoices]);

  return (
    <PageContainer>
      <NavBarMain currentPage="home" history={history}/>
      <Subcontainer>
        {invoices.length > 0 && (
        <InvoicesCard>
          <InvoicesTitle>{t('invoices')}</InvoicesTitle>
          {invoices.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((invoice) => (
            <InvoiceCard key={invoice.id}>
              <InvoiceAmount>{invoice.amount}€</InvoiceAmount>
              <InvoiceTitle>{new Date(invoice.timestamp).toLocaleDateString()}</InvoiceTitle>
              <DownloadButton onClick={async () => {
                const response = await getInvoice(invoice.id);

                const blob = new Blob([response.data], { type: 'application/pdf' });

                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `invoice_${invoice.id}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }}>
              <i className="fas fa-download"/>
            </DownloadButton>
            </InvoiceCard>
            ))}
          </InvoicesCard>
        )}
      </Subcontainer>
    </PageContainer>
  );
};
